import React from 'react';
import styled from 'styled-components';

import Layout from '../../components/Layout.jsx';
import SEO from '../../components/Seo.jsx';
import Hero from '../../components/Hero.jsx';
import IllustratedText from '../../components/IllustratedText.jsx';
import Card, { Cards } from '../../components/Card.jsx';
import { InfoBar } from '../../components/InfoSection.jsx';

import Developper from '../../icons/Developper.jsx';

import Email from '../../icons/Email.jsx';
import Puzzle from '../../icons/Puzzle.jsx';
import Web from '../../icons/Web.jsx';
import Python from '../../icons/Python.jsx';
import ReactLogo from '../../icons/React.jsx';
import Fullstack from '../../icons/Fullstack.jsx';
import Balance from '../../icons/Balance.jsx';
import LogoHoraireSource from '../../icons/Logo.jsx';
import LogoPosologiesSource from '../../icons/LogoPosologies.jsx';
import LogoReplicadSource from '../../images/replicad.png';

import color from '../../theme';

const Section = styled.div`
  padding: 50px 30px;

  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin-top: -15px;
  font-weight: 400;
  opacity: 0.7;
`;

const LogoHoraire = () => (
  <a
    href="https://horair.es/fr"
    css={`
      display: flex;
      justify-content: center;
      align-items: center;
      fill: white;
      background-color: rgb(39, 87, 107);
      height: 100%;
      width: 100%;
      padding: 8%;
      border-radius: 10%;
    `}
  >
    <LogoHoraireSource width="100%" />
  </a>
);

const LogoPosologies = () => (
  <a
    href="https://posologies.com"
    css={`
      display: flex;
      justify-content: center;
      align-items: center;
      fill: #ae2727;
      background-color: #f9ffd7;
      height: 100%;
      width: 100%;
      padding: 8%;
      border-radius: 10%;
      border: 1px solid #cecece;
    `}
  >
    <LogoPosologiesSource size="100%" />
  </a>
);

const LogoReplicad = () => (
  <a
    href="https://replicad.xyz"
    css={`
      display: flex;
      justify-content: center;
      align-items: center;
      fill: #ae2727;
      background-color: white;
      height: 100%;
      width: 100%;
      padding: 8%;
      border-radius: 10%;
      border: 1px solid #cecece;
      & img {
        max-width: 100%;
        object-fit: cover;
      }
    `}
  >
    <img src={LogoReplicadSource} size="100%" />
  </a>
);

const Name = styled.a`
  font-size: 0.6em;
  margin-top: 0.3em;
  color: inherit;
  text-decoration: none;
`;

const EndLink = styled.a`
  fill: white;
  stroke: ${color};
  height: 1.4em;
  display: flex;
  align-items: center;

  & > :not(:first-child) {
    margin-left: 0.5em;
  }
`;

const IndexPage = () => (
  <Layout main lang="fr">
    <SEO
      title="Steve Genoud | QuaroTech"
      lang="fr"
      description="Développeur indépendant fullstack"
    />
    <Hero lang="fr">
      <div>Développeur indépendant fullstack</div>
      <Name href="mailto:steve@quaro.tech">Steve Genoud</Name>
    </Hero>
    <Section>
      <IllustratedText Illustration={Developper}>
        <ul>
          <li>Vous avez besoin de compléter votre équipe pour un projet précis?</li>
          <li>Vous voulez contruire un premier prototype pour votre startup?</li>
          <li>Vous cherchez à développer un outil pour votre business?</li>
          <li>Vos employés ont besoin de formation sur un sujet spécifique?</li>
          <li>Vous cherchez un avis externe sur une technologie?</li>
        </ul>
        <p>
          En tant que développeur fullstack indépendant je peux vous aider à atteindre vos
          objectifs. Envoyez-moi un <a href="mailto:steve@quaro.tech">email</a>!
        </p>
      </IllustratedText>
    </Section>
    <Section>
      <SectionTitle>Compétences</SectionTitle>
      <Cards>
        <Card title="Fullstack" Illustration={Fullstack}>
          <p>A l'aise à la fois dans le frontend, le backend jusqu'aux opérations.</p>
        </Card>

        <Card title="Pragmatique et agile" Illustration={Balance}>
          <p>À la recherche de la solution adapté à la situation particulière.</p>
        </Card>

        <Card title="La complexité comme un défi" Illustration={Puzzle}>
          <p>La capacité d'appréhender un problème complexe rapidement.</p>
        </Card>

        <Card title="Plateforme web" Illustration={Web}>
          <p>Une maîtrise de la plateforme au-delà des outils du moment.</p>
        </Card>

        <Card title="Spécialiste Python" Illustration={Python}>
          <p>Plus de 10 ans d'expérience dans language et l'écosystème Python.</p>
        </Card>

        <Card title="Spécialiste React.js" Illustration={ReactLogo}>
          <p>Une compréhension en profondeur du framework React.js</p>
        </Card>
      </Cards>
    </Section>
    <Section>
      <SectionTitle>Réalisations</SectionTitle>
      <IllustratedText Illustration={LogoReplicad} small>
        <a href="https://replicad.xyz">Replicad</a> est un bibliothèque pour faire du dessin assisté
        par ordinateur (DAO ou CAD) dans le browser.
      </IllustratedText>
      <IllustratedText Illustration={LogoHoraire} small imageLeft>
        <a href="https://horair.es/fr">Horair.es</a> est une application web qui permet de créer et
        gérer des horaires complexes.
      </IllustratedText>
      <IllustratedText Illustration={LogoPosologies} small>
        <a href="https://posologies.com">Posologies.com</a> permet au professionels médicaux
        d'accéder aux notices de médicaments.
      </IllustratedText>
    </Section>
    <Section>
      <InfoBar>
        <EndLink href="mailto:steve@quaro.tech">
          <Email size="1em" />
          <span>Contact</span>
        </EndLink>
      </InfoBar>
    </Section>
  </Layout>
);

export default IndexPage;
